var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// import { Box, Card, Grid, Stack } from '@mui/material';
import { Box, Button, Card, Grid, IconButton, Stack, TextField } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import FormProvider, { RHFAsyncSelect, RHFTextField } from '../../../components/hook-form';
import { getAvailableBooks } from '../../../redux/slices/books/actions';
import { getAllOffices } from '../../../redux/slices/offices/actions';
import { dispatch } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { bookSaleTransactionValidation } from '../../../Validation/BookSaleTransaction';
import { createBookSale } from '../../../redux/slices/bookSales/actions';
import usePermission from '../../../hooks/usePermission';
import { bookSaleTransactionValidationForSuperAdmin } from '../../../Validation/bookSaleTransactionValidationForSuperAdmin';
import { useAuthContext } from '../../../auth/useAuthContext';
import { HOST_API_PUBLIC } from '../../../config-global';
var BookSaleForm = function (_a) {
    var _b = _a.isView, isView = _b === void 0 ? false : _b, currentBookSaleTransaction = _a.currentBookSaleTransaction, _c = _a.isEdit, isEdit = _c === void 0 ? true : _c;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var navigate = useNavigate();
    var goListPageBookSalesTransactions = function () { return navigate(PATH_DASHBOARD.books.bookSales.root); };
    var isSuperAdmin = usePermission().isSuperAdmin;
    var user = useAuthContext().user;
    var defaultValues = useMemo(function () {
        return isSuperAdmin
            ? {
                note: (currentBookSaleTransaction === null || currentBookSaleTransaction === void 0 ? void 0 : currentBookSaleTransaction.note) || '',
                items: (currentBookSaleTransaction === null || currentBookSaleTransaction === void 0 ? void 0 : currentBookSaleTransaction.items) || [
                    { book: null, salePrice: 0, quantitySold: 1 },
                ],
                office: (currentBookSaleTransaction === null || currentBookSaleTransaction === void 0 ? void 0 : currentBookSaleTransaction.office) || null,
            }
            : {
                note: (currentBookSaleTransaction === null || currentBookSaleTransaction === void 0 ? void 0 : currentBookSaleTransaction.note) || '',
                items: (currentBookSaleTransaction === null || currentBookSaleTransaction === void 0 ? void 0 : currentBookSaleTransaction.items) || [
                    { book: null, salePrice: 0, quantitySold: 1 },
                ],
            };
    }, [currentBookSaleTransaction]);
    var methods = useForm({
        resolver: yupResolver(isSuperAdmin ? bookSaleTransactionValidationForSuperAdmin : bookSaleTransactionValidation),
        defaultValues: defaultValues,
    });
    var handleSubmit = methods.handleSubmit, _d = methods.formState, isSubmitting = _d.isSubmitting, isDirty = _d.isDirty, control = methods.control, register = methods.register, watch = methods.watch;
    var _e = useFieldArray({
        control: control,
        name: 'items',
    }), fields = _e.fields, append = _e.append, remove = _e.remove;
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dispatch(createBookSale(__assign(__assign({}, data), { office: data.office ? (_a = data.office) === null || _a === void 0 ? void 0 : _a._id : (_b = user === null || user === void 0 ? void 0 : user.office) === null || _b === void 0 ? void 0 : _b._id })))
                            .unwrap()
                            .then(function (res) {
                            enqueueSnackbar(res.message);
                            goListPageBookSalesTransactions();
                        })
                            .catch(function (err) { return enqueueSnackbar(err.message, { variant: 'error' }); })];
                case 1:
                    _c.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(FormProvider, __assign({ methods: methods, onSubmit: handleSubmit(onSubmit) }, { children: _jsx(Card, __assign({ sx: { p: 3, width: '75%' } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [isSuperAdmin && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(RHFAsyncSelect, { name: "office", label: "Office", placeholder: "Select Office *", fetchData: function (params) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, dispatch(getAllOffices(params))
                                                .unwrap()
                                                .then(function (res) { return res; })];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                });
                            }); }, getOptionLabel: function (option) { return option.name; }, getOptionValue: function (option) { return option._id; }, onChange: function (office) {
                                methods.setValue('office', office);
                                methods.setValue('items', [{ book: null, salePrice: 0, quantitySold: 0 }]);
                            }, sx: {
                                padding: '.55rem 0.5rem',
                                minWidth: 150,
                            } }) }))), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, __assign({}, register('note'), { label: "Note", fullWidth: true, multiline: true, rows: 3, variant: "outlined" })) })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsxs(Box, __assign({ display: "flex", flexDirection: "column", gap: 2 }, { children: [fields.map(function (field, index) {
                                        var _a, _b;
                                        return (_jsxs(Box, __assign({ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr auto", gap: 2, alignItems: "center" }, { children: [_jsx(RHFAsyncSelect, { disable: isView || (isSuperAdmin && !watch().office), name: "items.".concat(index, ".book"), label: "Book", sx: {
                                                        padding: '.55rem 0.5rem',
                                                        minWidth: 150,
                                                    }, placeholder: "Select Book *", isSearchable: true, fetchData: function (params) { return __awaiter(void 0, void 0, void 0, function () {
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, dispatch(getAvailableBooks(__assign(__assign({}, params), { filterOffice: isSuperAdmin ? watch().office : undefined })))
                                                                        .unwrap()
                                                                        .then(function (res) { return res; })];
                                                                case 1: return [2 /*return*/, _a.sent()];
                                                            }
                                                        });
                                                    }); }, getOptionLabel: function (option) {
                                                        var _a, _b, _c, _d, _e, _f, _g;
                                                        return "".concat((_a = option === null || option === void 0 ? void 0 : option.book) === null || _a === void 0 ? void 0 : _a.name, " ").concat(((_b = option === null || option === void 0 ? void 0 : option.book) === null || _b === void 0 ? void 0 : _b.level) ? "| ".concat((_d = (_c = option === null || option === void 0 ? void 0 : option.book) === null || _c === void 0 ? void 0 : _c.level) === null || _d === void 0 ? void 0 : _d.name) : '', "  ").concat(((_e = option === null || option === void 0 ? void 0 : option.book) === null || _e === void 0 ? void 0 : _e.section) ? "| ".concat((_g = (_f = option === null || option === void 0 ? void 0 : option.book) === null || _f === void 0 ? void 0 : _f.section) === null || _g === void 0 ? void 0 : _g.name) : '');
                                                    }, getOptionValue: function (option) { return option._id; }, onChange: function (book) {
                                                        methods.setValue("items.".concat(index, ".salePrice"), book.price);
                                                        methods.setValue("items.".concat(index, ".book"), book);
                                                    } }), watch().items[index].book &&
                                                    Object.keys(watch().items[index].book).length > 0 && (_jsx("div", __assign({ style: { maxWidth: 100, height: 100, margin: '0 auto' } }, { children: _jsx("img", { src: "".concat(HOST_API_PUBLIC, "/uploads/").concat((_a = watch().items[index].book) === null || _a === void 0 ? void 0 : _a.image), alt: ((_b = watch().items[index].book) === null || _b === void 0 ? void 0 : _b.name) || 'book', style: {
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain',
                                                            borderRadius: '12px',
                                                        }, crossOrigin: "anonymous" }) }))), _jsx(RHFTextField, { name: "items.".concat(index, ".salePrice"), label: "Price", type: "number", disabled: !isEdit, onChange: function (e) {
                                                        return methods.setValue("items.".concat(index, ".salePrice"), parseFloat(e.target.value));
                                                    } }), _jsx(RHFTextField, { name: "items.".concat(index, ".quantitySold"), label: "Quantity", type: "number", disabled: !isEdit }), !isView && (_jsx(IconButton, __assign({ onClick: function () { return remove(index); }, color: "error" }, { children: _jsx(Delete, {}) })))] }), field.id));
                                    }), !isView && (_jsx(Button, __assign({ startIcon: _jsx(Add, {}), variant: "outlined", onClick: function () {
                                            return append({ book: {}, salePrice: 0, quantitySold: 1 });
                                        } }, { children: "Add Item" })))] })), !isView && (_jsx(Stack, __assign({ alignItems: "flex-end", sx: { mt: 3 } }, { children: _jsx(LoadingButton, __assign({ type: "submit", variant: "contained", disabled: isEdit && !isDirty, loading: isSubmitting }, { children: currentBookSaleTransaction ? (_jsxs("div", { children: [_jsx("span", { children: "Save Changes" }), " ", '  ', ![0, NaN].includes(watch().items.reduce(function (sum, item) { return sum + item.salePrice; }, 0)) && (_jsxs("span", { children: ["(Total amount:", ' ', watch().items.reduce(function (sum, item) { return sum + item.salePrice * item.quantitySold; }, 0), ' ', "TND)"] }))] })) : (_jsxs("div", { children: [_jsx("span", { children: "Create Book Sale" }), " ", '  ', ![0, NaN].includes(watch().items.reduce(function (sum, item) { return sum + item.salePrice; }, 0)) && (_jsxs("span", { children: ["(Total amount:", ' ', watch().items.reduce(function (sum, item) { return sum + item.salePrice * item.quantitySold; }, 0), ' ', "TND)"] }))] })) })) })))] }))] })) })) })));
};
export default BookSaleForm;
